<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>

      <AddButton
        :create-function="backToExams"
        :icon="'mdi-reply-circle'"
        :title="'BACK TO EXAMS'"
        v-if="openScoreTable"
      />
    </v-card-actions>

    <v-card>
      <e-facilities :set-data="setActive" />
      <LoadExaminations
        :is-open="openExamsLoader"
        :accept-function="getExamsParams"
        :examParams="examParams"
        :buttonText="'Enter Scores'"
      />

      <v-form ref="form" v-model="valid" v-if="openScoreTable">
        <v-data-table
          :item-class="setRowStyle"
          :loading="loading"
          :headers="headers"
          :items="students"
          :server-items-length="params.total"
          :options.sync="pagination"
          :items-per-page="params.size"
          class="elevation-1 row-height-50"
          :no-data-text="'Data not available from this resource'"
          :footer-props="{
            'items-per-page-options': [20, 50, 100, 300, 500, -1],
          }"
        >
          <template v-slot:top>
            <v-card-title>
              <!-- <v-col cols="3" sm="12" md="3">
                <v-select
                  v-model="exam"
                  :hint="examData"
                  :items="exams"
                  item-text="name"
                  label="Select Examination"
                  persistent-hint
                  return-object
                  @change="loadStudents($event)"
                  @click:clear="loadStudents()"
                  single-line
                  required
                  clearable
                ></v-select>
              </v-col> -->

              <v-col cols="2" sm="12" md="2" class="pa-5">
                <v-select
                  label="Filter by Stream"
                  :items="streams"
                  :item-text="'name'"
                  @change="loadStudentsByStreams($event)"
                  @click:clear="loadStudentsByStreams()"
                  return-object
                  required
                  clearable
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <!-- 
              <AddButton
                :create-function="backToExams"
                :icon="'mdi-view-grid-plus-outline'"
                :title="'BACK TO EXAMS'"
                v-if="openScoreTable"
              /> -->
              <v-col cols="3" sm="12" md="3" class="pa-0">
                <SearchField
                  :search-function="searchOptions"
                  :prepend-icon="'mdi-magnify'"
                  :label="'Search Students'"
                  :append-icon="''"
                  v-if="items.length > 0"
                />
              </v-col>
            </v-card-title>
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- {{ item.selected }} -->
            <div v-if="item.sat_for">
              <v-text-field
                label="Score"
                dense
                centered
                type="number"
                @wheel="$event.target.blur()"
                min="0"
                max="100"
                v-model="item.score"
                :rules="[scoreRule]"
                required
                outlined
                class="score-input text-field"
                @change.native="getScore(item)"
                height="40"
                width="40"
                hide-details
              ></v-text-field>
            </div>

            <v-icon v-else color="danger --text" class="mr-1 pr-1 pl-10"
              >mdi-window-close</v-icon
            >
          </template>
          <template v-slot:body.append v-if="scores.length > 0">
            <tr>
              <td colspan="7"></td>

              <td>
                <v-col class="text-right">
                  <v-btn
                    class="ma-2"
                    color="primary"
                    @click="openConfirmDialog"
                    :disabled="!valid"
                  >
                    <v-icon>mdi-content-save-all-outline</v-icon>
                    save SCORES
                  </v-btn>
                </v-col>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-form>
    </v-card>

    <ConfirmDialog
      :reject-function="closeConfirmDialog"
      :accept-function="saveScores"
      :item="item"
      :isOpen="openConfirm"
      :title="
        `Are you sure you want to save scores of ${scores.length} of ${params.total} students ?`
      "
      :loading="loading"
    />
  </div>
</template>

<script>
import * as scores from "./services";
import { get as getClasses, streams } from "../school-classes/services";
import { get as getExams } from "../examinations/services";
import { v4 as uuidv4 } from "uuid";
import LoadExaminations from "./components/LoadExamComponent.vue";
import _ from "lodash";

export default {
  components: {
    LoadExaminations,
  },
  name: "ExaminationScores",
  data() {
    return {
      valid: true,
      selected: [],
      schoolClassesLists: [],
      schoolClass: {},
      title: "Manage Examinations Score",
      isEditing: false,
      formData: {},
      examParams: {},
      dialog: false,
      isOpen: false,
      openExamsLoader: false,
      loading: false,
      openConfirm: false,
      params: {},
      item: null,
      schoolClassStream: "",
      items: [],
      openScoreTable: false,
      streams: [],
      exams: [],
      scores: [],
      exam: {},
      school: {},
      examData: null,
      scoreRule: (v) => {
        () => true || "";
        if ((isNaN(v) && v == undefined) || v == "") return true;
        if (new RegExp(/^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/).test(v))
          return true;
        // return "Score must be in range 0-100 (2 Decimal Places)";
        return false;
      },
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Exam Number",
          align: "start",
          sortable: false,
          value: "student_class.student_necta_serial",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullName",
          class: "primary--text",
        },

        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "student_class.student.gender.descriptions",
          class: "primary--text",
        },
        {
          text: "Class",
          align: "start",
          sortable: false,
          value: "schoolClass.name",
          class: "primary--text",
        },
        {
          text: "Stream",
          align: "start",
          sortable: false,
          value: "school_class_stream.name",
          class: "primary--text",
        },
        {
          text: "Year",
          align: "start",
          sortable: false,
          value: "academic_year.name",
          class: "primary--text",
        },
        {
          text: "Scores",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },

        {
          text: "Grade",
          value: "grade",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    students() {
      return this.items.map((item, index) => ({
        ...item,
        index: ++index,
        schoolClass: item.school_class.school_class,
        fullName: item.student_class.student.display_name,
        student: item.student_class.student,
        grade: item.exam_grade
          ? item.exam_grade.grade + "  -  " + item.exam_grade.descriptions
          : null,
      }));
    },

    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;
      },
    },

    academicYear() {
      return this.$mixins.academicYear();
    },
  },

  methods: {
    setActive(data) {
      this.openExamsLoader = true;

      this.openExamsLoader = true;
      this.examParams = data;
    },

    getExamsParams(data, action) {
      this.exam = data;
      if (action === "openScores") {
        this.openExamsLoader = false;
        this.openScoreTable = true;
      } else {
        this.openExamsLoader = true;
        this.openScoreTable = false;
      }

      this.loadStudents(data);
    },

    backToExams() {
      this.openExamsLoader = true;
      this.openScoreTable = false;
    },

    getScore(data) {
      this.school = data.school;
      this.scores.push(data);
      //remove duplicates but keep the last updated score!
      this.scores.reverse();
      this.scores = _.uniqBy(this.scores, "student.id");
      //delete row with empty score
      this.scores.map((item, index) =>
        item.score == "" || item.score == null || item.score == undefined
          ? this.scores.splice(index, 1)
          : item
      );
    },

    saveScores() {
      let payload = {
        scores: this.scores,
        exam: this.exam,
        ...this.school,
      };

      scores.create(payload).then((response) => {
        this.init(this.params);
        this.closeConfirmDialog();
      });
    },

    fetchExams() {
      getExams().then((response) => {
        this.exams = response.data.data;
      });
    },
    searchOption() {},

    closeConfirmDialog() {
      this.openConfirm = false;
    },

    init(params) {
      let payload = {
        ...params,
        examId: this.exam?.id,
      };
      this.loading = true;
      scores
        .get(payload)
        .then((response) => {
          this.loading = false;
          this.params.total = response.data.total;
          this.items = response?.data?.data;
          this.scores = [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    exit() {
      return;
    },

    getSchoolClasses() {
      getClasses().then((response) => {
        this.schoolClassesLists = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                ...item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudents(data = null) {
      this.examData = data
        ? ` Exam Details: ${data.school_class.name} ${data.type.name} - ${data.subject.name} held on ${data.date}`
        : "";

      this.params.schoolClassId =
        data && data.school_class ? data.school_class.id : null;
      this.params.subjectId = data && data.subject ? data.subject.id : null;
      this.params.yearId = data && data.year ? data.year.id : null;
      this.params.typeId = data && data.type ? data.type.id : null;

      this.init(this.params);
      // this.loadStreams(data);

      if (data === null) {
        this.schoolClass = {};
      }
    },

    loadStreams(data) {
      //.params.schoolClassId = data && data.id ? data.id : null;
      //this.loadStudentsByStreams(this.params);
      streams(this.params).then((response) => {
        this.streams = response.data[0]
          ? response.data[0]
              .map((item) => ({
                ...item,
              }))
              .sort(function(a, b) {
                return a.name > b.name ? 1 : -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudentsByStreams(data = null) {
      this.params.schoolClassStreamId = data && data.id ? data.id : null;
      this.init(this.params);
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    searchOptions(optionName) {
      (this.params.search = optionName ? optionName : null),
        this.init({
          size: this.params.size,
          total: this.params.total,
          ...this.params,
        });
    },
    setRowStyle(item) {
      /* Here you can also do some validation
      in case you want to apply different css 
      classes depending on item attributes 
      values */

      if (item.sat_for === false) {
        return "absent-style";
      }
      return "style-1";
    },
    save(data) {
      this.loading = true;

      scores
        .create(data)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },

    getGenderList() {
      this.$mixins.fetchByCategory("GENDER").then((response) => {
        this.genders = response;
      });
    },

    reAssignNumbers() {
      let params = {
        ...this.params,
        academicYear: this.academicYear.id,
      };
      student.reAssignNumbers(params).then((response) => {
        this.openConfirm = false;
        this.init(this.params);
      });
    },

    openConfirmDialog() {
      this.openConfirm = true;
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        // this.init(this.params);
      },
      update() {
        this.loading = true;
        // this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getSchoolClasses();
    this.getGenderList();
    this.fetchExams();
  },
};
</script>

<style scoped>
.score-input {
  min-width: 100px !important;
  max-width: 100px !important;
  font-size: 12px !important;
  padding: 5px !important;
}

.score-input >>> input {
  text-align: center;
}

.score-input >>> .v-text-field >>> .v-label {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* top: 20px; */
  white-space: nowrap;
  pointer-events: none;
}

.score-input >>> .v-label {
  font-size: 10px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  transform-origin: top 50%;
  &.v-label--active {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
.score-input >>> .v-messages {
  flex: 1 1 auto;
  font-size: 8px;
  min-height: 10px;
  min-width: 1px;
  position: relative;
}
.score-input >>> .v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
  width: 50px !important;
  height: 50px !important;
}
.score-input >>> .v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 8px !important;
  letter-spacing: normal;
  max-width: 40%;
  text-align: center;
}
.score-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.score-input >>> input::-webkit-outer-spin-button,
.score-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.theme--light.v-datatable .v-datatable__actions {
  position: fixed;
  bottom: 0;
  width: 100%;
}

>>> .style-1 td {
  height: 10px !important;
}
>>> .absent-style td {
  background-color: tan;
}
</style>
