import axios from "axios";
const ENDPOINT = "/api/v1/exam-scores";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT + '/create', payload);
};



export { get, create };

